import Banner from "../containers/WebApp/SecurityCompliance/Banner";
import Section from "../containers/WebApp/SecurityCompliance/Section";
import TeamBoard from "../containers/WebApp/TeamBoard";
import SEO from "../components/SEO";
import React from "react";

const SecurityCompliance = () => {
  return (
    <>
      <SEO title="Security Compliance" />
      <Banner />
      <Section />
      <TeamBoard />
    </>
  );
};

export default SecurityCompliance;
