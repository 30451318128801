import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_ABOUT } from "../../../../common/data/WebApp";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, {
  Row,
  Col,
  TopHeading,
  ColumnContents,
} from "./section.style";
const Section = () => {
  const { sections } = SECTION_ABOUT;
  return (
    <>
      <SectionArea>
        <Container>
          <Row className="rowContents">
            <TopHeading>
              <Heading as="h2" content={sections.title} />
            </TopHeading>
            <Box className="blockTitle">
              <Text as="p" content={sections.text1} />
            </Box>
            <Box className="blockTitle">
              <Text as="p" content={sections.text2} />
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <SectionArea>
        <Container>
          <Row>
            <Col className="blockTitle">
              <Heading as="h3" content="Improving Technologies" />
              <Text as="p" content={sections.improving_technologies} />
            </Col>
            <Col className="blockTitle">
              <Heading as="h3" content="Improving Diversity" />
              <Text as="p" content={sections.improving_diversity} />
            </Col>
            <Col className="blockTitle">
              <Heading as="h3" content="Improving Support" />
              <Text as="p" content={sections.improving_support} />
            </Col>
          </Row>
        </Container>
      </SectionArea>
    </>
  );
};

export default Section;
